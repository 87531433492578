<template>
  <page-container>
    <div class="d-flex flex-column content-pane hide-scrollbar">
      <v-card color="grey darken-4 flex-grow-0">
        <div class="v-toolbar__content py-4">
          <v-autocomplete v-model="selectedSingers" :items="singerList" label="Singers" outlined filled multiple rounded clearable
            dense hide-details :deletable-chips="selectedSingers.length > 1"
            append-icon="mdi-account-music-outline"
            :menu-props="{ offsetY: true, maxHeight: '400' }" class="mr-3" style="max-width: 400px">
            <template v-slot:selection="{ item, index }">
              <template v-if="index === 0">
                <span v-if="selectedSingers.length === 1" class="subtitle-2">
                  {{ item }}
                </span>
                <span v-else class="caption">
                  {{ selectedSingers.length }} selected
                </span>
              </template>
            </template>
          </v-autocomplete>
          <v-spacer></v-spacer>
          <v-select
            v-model="selectedSortOption"
            :items="sortOptions"
            item-value="value"
            item-text="label"
            label="Sort by"
            rounded
            outlined
            filled
            dense
            hide-details
            append-icon="mdi-sort"
            :menu-props="{ offsetY: true, maxHeight: '400' }"
            class="mr-3"
            style="max-width: 180px"
          >
            <template v-slot:selection="{ item }">
              <span class="subtitle-2">
                {{ item.label }}
              </span>
            </template>
          </v-select>
          <v-text-field v-if="$vuetify.breakpoint.mdAndUp" v-model="searchText" label="Search"
            outlined rounded filled solo dense clearable hide-details append-icon="mdi-magnify"
            :style="{ maxWidth: $vuetify.breakpoint.mdAndUp ? '360px' : '220px' }"></v-text-field>
        </div>
        <div v-if="!$vuetify.breakpoint.mdAndUp" class="v-toolbar__content pb-4">
          <v-text-field v-model="searchText" label="Search" dense clearable hide-details
            append-icon="mdi-magnify"></v-text-field>
        </div>
      </v-card>
      <div class="pt-6 flex-grow-1 overflow-y-auto">
        <song-list ref="songList" :songList="songList" :selectedSingers="selectedSingers" :searchText="searchText"
          :sortBy="sortBy" :sortDesc="sortDesc" @edit="editSong"></song-list>
      </div>
    </div>
    <v-speed-dial v-if="isUserAuthenticated" v-model="fab" fixed bottom right direction="top" :open-on-hover="true"
      transition="scale" class="mr-3">
      <template v-slot:activator>
        <v-btn v-model="fab" color="secondary darken-2" dark fab>
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-account-circle
          </v-icon>
        </v-btn>
      </template>
      <v-btn fab dark small color="indigo" @click="addSong">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn fab dark small color="purple" @click="$refs.songList.downloadSongs()">
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-btn fab dark small color="brown" @click="$refs.songList.uploadSongs()">
        <v-icon>mdi-upload-outline</v-icon>
      </v-btn>
    </v-speed-dial>
    <v-dialog v-model="showAddNewDialog" fullscreen>
      <add-song ref="addSongDialog" @close="showAddNewDialog = false" :key="showAddNewDialog" :type="actionType" :singerList="singerList"
        :lyricistList="lyricistList" :composerList="composerList" :productionList="productionList"></add-song>
    </v-dialog>
  </page-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import firebase from 'firebase'

const GG = 'Goutam Ghosal'

export default {
  data: () => ({
    songList: [],
    actionType: 'add',
    showAddNewDialog: false,
    sortOptions: [
      { label: 'Popularity', value: '-rating' },
      { label: 'Newest First', value: '-release_year' },
      // { label: 'Oldest First', value: 'release_year' },
      { label: 'Title [A-Z]', value: 'title' },
      { label: 'Title [Z-A]', value: '-title' },
      { label: 'Singer [A-Z]', value: 'singer' },
      { label: 'Singer [Z-A]', value: '-singer' }
    ],
    selectedSortOption: '-rating',
    selectedSingers: [],
    sortBy: 'rating',
    sortDesc: true,
    searchText: '',
    fab: false,

    singerList: [GG],
    lyricistList: [GG],
    composerList: [GG],
    productionList: []
  }),
  computed: {
    ...mapState(['authUser']),
    ...mapGetters([
      'currentUser',
      'isUserAuthenticated',
      'isUserSuperAdmin'
    ])
  },
  created () {
    this.showSpinner()

    // Open a particular song from the shared link (OLD LINK : TODO REMOVE IN FUTURE)
    const songId = this.$route.query.song_id || ''
    if (songId) {
      this.$router.push('/discography/' + songId) // Redirect to the newly implemented page
      return
    }

    firebase.firestore()
      .collection('songs')
      .orderBy(this.sortBy, this.sortDesc ? 'desc' : 'asc')
      .onSnapshot(
        snapshot => {
          try {
            this.songList = snapshot.docs.map(doc => {
              return {
                doc_id: doc.id,
                ...doc.data()
              }
            })
            console.log('Songs entry: ' + this.songList.length)
            this.updateCreatorList()
          } finally {
            this.hideSpinner()
          }
        },
        error => {
          if (error.code === 'resource-exhausted') {
            console.error('Quota exceeded for Firestore reads. Consider reducing read frequency or upgrading your plan.')
          } else {
            console.error('An unexpected Firestore error occurred:', error)
          }
          this.songList = []
          this.hideSpinner()
          this.showToast({ text: 'Songs cannot be fetched from the database. Please try again later.', color: 'error' })
        }
      )
  },
  mounted () {
    this.filterByQueryParams()
  },
  watch: {
    $route (to, from) {
      this.filterByQueryParams()
      /* console.log(this.$refs.mainContainer.scrollTop)
      this.$refs.mainContainer.$el.scrollIntoView({ block: 'end', scrollBehavior: 'smooth' }) */
    },
    selectedSortOption () {
      if (this.selectedSortOption.startsWith('-')) {
        this.sortDesc = true
        this.sortBy = this.selectedSortOption.replace('-', '')
      } else {
        this.sortBy = this.selectedSortOption
        this.sortDesc = false
      }
    }
  },
  methods: {
    ...mapActions(['getLyric']),
    updateCreatorList () {
      const singerList = []
      this.songList.forEach(song => {
        if (singerList.indexOf(song.singer) === -1) singerList.push(song.singer)
        if (this.lyricistList.indexOf(song.lyricist) === -1) this.lyricistList.push(song.lyricist)
        if (this.composerList.indexOf(song.composer) === -1) this.composerList.push(song.composer)
        if (song.production && this.productionList.indexOf(song.production) === -1) this.productionList.push(song.production)
      })
      this.singerList = [GG, ...singerList.sort()]
      this.productionList.sort()
    },
    filterByQueryParams () {
      if (this.$route.query.singer) {
        if (this.$route.query.singer === 'GG') {
          this.selectedSingers.length = 0
          this.selectedSingers.push(GG)
        } else if (this.$route.query.singer === 'notGG') {
          this.selectedSingers.length = 0
          this.selectedSingers.push('!GG')
        }
        this.$router.replace({ query: null })
      }
    },
    addSong () {
      this.actionType = 'add'
      this.showAddNewDialog = true
    },
    editSong (item) {
      this.actionType = 'edit'
      this.showAddNewDialog = true
      setTimeout(() => {
        this.$refs.addSongDialog.itemId = item.doc_id
        this.$refs.addSongDialog.song_id = item.song_id
        this.$refs.addSongDialog.title = item.title
        this.$refs.addSongDialog.title_bn = item.title_bn
        this.$refs.addSongDialog.singer = item.singer
        this.$refs.addSongDialog.composer = item.composer
        this.$refs.addSongDialog.lyricist = item.lyricist
        this.$refs.addSongDialog.album = item.album
        this.$refs.addSongDialog.rating = item.rating
        this.$refs.addSongDialog.production = item.production
        this.$refs.addSongDialog.release_year = item.release_year
        this.$refs.addSongDialog.link = item.link
        this.$refs.addSongDialog.yt_id = item.yt_id
        // this.$refs.addSongDialog.thumbnailLink = item.thumbnailLink
        this.$refs.addSongDialog.additionalInfo = item.additionalInfo || ''

        this.$refs.addSongDialog.getThumbnailURL()
      }, 100)
      this.getLyric(item.song_id)
        .then(snapshot => {
          if (!snapshot.empty) {
            const lyric = snapshot.docs[0].data()
            this.$refs.addSongDialog.lyrics_bn = lyric.lyrics_bn
          }
        })
    }
  },
  components: {
    AddSong: () => import('./AddSong'),
    SongList: () => import('./SongList'),
    PageContainer: () => import('@/components/reusable/PageContainer')
  }
}
</script>

<style lang="scss" scoped>
.content-pane {
  height: calc(100vh - 100px);
}
</style>
